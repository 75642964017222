.SingleCorrectGrid-Container,
.MultiCorrectGrid-Container {
  position: relative;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    right: -15px;
    background: linear-gradient(
      90deg,
      rgba(245, 247, 255, 0) 0%,
      rgba(245, 247, 255, 0.5) 50%,
      rgba(245, 247, 255, 1) 80%
    );
    width: 32px;
    height: 100%;
  }

  &.Scrolled {
    &::after {
      display: none;
    }
    .SingleCorrectGrid-Inner,
    .MultiCorrectGrid-Inner {
      & > div > div:first-of-type {
        box-shadow: 2px 1px 0px rgb(231, 231, 231);
      }
    }
  }
}

.SingleCorrectGrid-Inner,
.MultiCorrectGrid-Inner {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: -11px;
  margin-right: -15px;

  & > div {
    flex-wrap: nowrap;
  }

  & > div > div {
    min-width: 72px;
    padding: 0 2px;
    word-break: break-word;
  }

  // Left column
  & > div > div:first-of-type {
    position: sticky;
    left: 0;
    min-width: 80px;
    background-color: #f5f5f7;
    word-break: break-word;
    z-index: 100;
  }

  & > div > div:nth-child(2) {
    margin-left: 4px;
  }
}

// Linear Scale
.LinearScale-RadioGroup {
  label {
    display: flex;
    flex-direction: row;
    margin: 0;
  }
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
}

// Single Choice
.SingleChoice-RadioGroup {
  label {
    display: flex;
    flex-direction: row;
    margin: 0;
  }
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
}

// Multi Correct
.MultiCorrect-FormGroup {
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
}
