.not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  &__icon {
    width: 180px;
    height: auto;

    @include mobile {
      width: 128px;
    }
  }

  &__message {
    color: $text-color;
    font-size: 1.5rem;

    @include mobile {
      font-size: 1rem;
    }
  }
}
