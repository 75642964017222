$content-width: 460px;

.page {
  height: 100vh;
  overflow: auto;
  margin: 0 auto;
  width: $content-width;
  padding: 1rem;
  overflow: auto;
  text-align: center;

  @include mobile {
    width: 100%;
  }

  &--center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--hcenter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > * {
    flex-shrink: 0;
  }

  &__logo {
    max-height: 60px;
    max-width: 100%;
    margin: 4.5rem 0;
    margin-top: auto;

    @include mobile {
      margin: 2.5rem 0;
      margin-top: auto;
    }
  }

  &__message {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.334;
    color: $text-color;
    margin-bottom: 3rem;
  }

  &__input {
    margin-bottom: 2.5rem;
  }

  &__action {
    @extend .button;
    width: 220px;
    margin-bottom: 1.5rem;
    text-decoration: none;

    &--blue {
      color: $white;
      background-color: $blue;
    }

    &--google {
      color: $white;
      background-color: #449ff4;
    }

    &--fb {
      color: $white;
      background-color: #4267b2;
    }

    &--yelp {
      color: $white;
      background-color: #d32323;
    }
  }

  &__branding-link {
    text-align: center;
    text-decoration: none;
    margin-bottom: auto;
  }

  &__powered-by {
    color: rgba($color: $black, $alpha: 0.7);
    font-size: 0.75rem;
    margin: 2rem 0 0.5rem;
  }

  &__branding {
    width: auto;
    height: 30px;
    margin-bottom: 1rem;
  }
}
